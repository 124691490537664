// Fonts
// Sentence:
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
// Text:
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/duotone';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/thin';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';

// Animazioni HOVER.CSS importate singolarmente
@import "~hover.css/scss/options";
@import "~hover.css/scss/mixins";
@import "~hover.css/scss/hacks";
@import "~hover.css/scss/effects/2d-transitions/forward";
@if $includeClasses {
	.#{$nameSpace}-forward {
		@include forward();
	}
}
@import "~hover.css/scss/effects/icons/icon-forward";
@if $includeClasses {
	.#{$nameSpace}-icon-forward {
		@include icon-forward();
	}
}
@import "~hover.css/scss/effects/icons/icon-down";
@if $includeClasses {
	.#{$nameSpace}-icon-down {
		@include icon-down();
	}
}
@import "~hover.css/scss/effects/icons/icon-sink";
@if $includeClasses {
	.#{$nameSpace}-icon-sink {
		@include icon-sink();
	}
}
